import React from 'react';
import {BREAKPOINT} from '../../lib/styles';

const Button = (props) => {
    const {
        disabled,
        className = '',
        children,
        secondary,
        ghost,
        outlined,
        stickyButton,
        rounded,
        style = {},
        overrides = {},
    } = props;
    const {className: jsxClassName = '', styles: overrideStyles = ''} = overrides;

    const classNames = [
        className,
        jsxClassName,
        secondary ? 'secondary' : '',
        rounded ? 'rounded' : '',
        ghost ? 'ghost' : '',
        outlined ? 'outlined' : '',
        stickyButton ? 'stickyButton' : '',
    ]
        .filter((i) => i.length)
        .join(' ');

    return (
        <button className={classNames} style={{opacity: disabled ? '0.65' : 1, ...style}}>
            {children}
            <style jsx>{`
                /* button-primary */
                button {
                    background-color: var(--primary-300);
                    padding: 16px 25px;
                    border-radius: 8px;
                    border: 0;
                    cursor: pointer;
                    font-family: var(--title-font);
                    font-size: 12px;
                    font-weight: 600;
                    color: #fff;
                    letter-spacing: -0.16px;
                    line-height: 16px;
                    width: 100%;
                }
                button:hover,
                button:active,
                button:focus {
                    background-color: var(--primary-400);
                    outline: 0;
                }
                button:disabled {
                    background: var(--gray-200);
                    outline: 0;
                    color: var(--gray-300);
                }

                /* button-secondary */
                button.secondary {
                    background: #fff;
                    border: 1px solid var(--primary);
                    color: var(--primary);
                }
                button.secondary:hover,
                button.secondary:active,
                button.secondary:focus {
                    background-color: var(--primary-400);
                    opacity: 0.3;
                    outline: 0;
                    color: #fff;
                }
                button.secondary:disabled {
                    background: var(--gray-200);
                    opacity: 0;
                    border: 1px solid var(--gray-200);
                    outline: 0;
                    color: var(--gray-300);
                }
                button.rounded {
                    border-radius: 36px 36px 36px 36px;
                }
                button.secondary.ghost {
                    background-color: #fff;
                    border: 2px solid var(--primary-100);
                    box-shadow: none;
                    padding: 10px 24px;
                    color: #000;
                }
                button.outlined {
                    background-color: #fff;
                    border: 2px solid var(--primary-300);
                    box-shadow: none;
                    padding: 10px 24px;
                    color: var(--primary-300);
                    padding: 14px 16px;
                    font-weight: 600;
                }
                button.outlined:hover,
                button.outlined:active,
                button.outlined:focus {
                    background-color: rgba(239, 57, 90, 0.3);
                    color: var(--primary-300);
                }

                button.stickyButton {
                    background: var(--primary-300);
                    border-radius: 8px;
                    border: 1px solid rgb(239, 57, 90);
                    height: 48px;
                    padding: 0px 25px;
                    width: 100%;
                }
                button.stickyButton:hover,
                button.stickyButton:active,
                button.stickyButton:focus {
                    background-color: var(--primary-400);
                    outline: 0;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    button.stickyButton.outlined {
                        padding: 14px 22px;
                    }
                }
            `}</style>
            {overrideStyles}
        </button>
    );
};

export default Button;
