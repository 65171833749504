import React, {Component} from 'react';
import {BREAKPOINT, px} from '../../lib/styles';

//transparent 1px by 1px gif
const placeholder =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export default class Image extends Component {
    render() {
        const {
            overlay,
            style,
            handleClick,
            children,
            src,
            defer,
            className,
            alt,
            width,
            height,
            mobilePosition,
            desktopImage,
            objectFit,
        } = this.props;

        if (overlay) {
            return (
                <div style={style} onClick={handleClick}>
                    {children}
                    <style jsx>
                        {`
                            div {
                                background-size: cover;
                                background-image: linear-gradient(
                                        rgba(20, 20, 20, 0.75),
                                        rgba(20, 20, 20, 0.75)
                                    ),
                                    url(${src});
                                background-repeat: no-repeat;
                            }
                        `}
                    </style>
                </div>
            );
        }

        return (
            <>
                {children ? (
                    <div
                        onClick={handleClick}
                        data-bg={defer ? `url(${src})` : null}
                        loading={defer && 'lazybg'}
                        style={style}
                        className={className}>
                        {children}
                    </div>
                ) : (
                    <picture>
                        {desktopImage && (
                            <source
                                media="(min-width: 415px)"
                                data-srcset={defer && desktopImage}
                                srcSet={defer ? placeholder : desktopImage}
                            />
                        )}
                        <img
                            onClick={handleClick}
                            data-src={defer ? src : null}
                            loading={defer && 'lazyel'}
                            src={defer ? placeholder : src}
                            style={style}
                            className={className}
                            alt={alt || 'american addiction centers photo'}
                        />
                    </picture>
                )}

                <style jsx>{`
                    img {
                        display: inline-block;
                        max-width: 100%;
                        vertical-align: top;
                        width: ${width ? px(width) : 'auto'};
                        height: ${height ? px(height) : 'auto'};
                        background-size: cover;
                        object-fit: ${objectFit ? `${objectFit}` : 'cover'};
                    }
                    div {
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-image: url(${defer ? placeholder : src});
                        background-position: ${mobilePosition ? mobilePosition : '50%'};
                        width: ${width ? px(width) : 'auto'};
                        height: ${height ? px(height) : 'auto'};
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        div {
                            background-position: 50%;
                        }
                    }
                `}</style>
            </>
        );
    }
}
