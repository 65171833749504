import React, {useState} from 'react';
import Link from 'next/link';
import IconQuestionCircle from 'aac-components/components/Icons/Basic/IconQuestionCircle';

import Modal from '../Modal';
import Image from '../Image';

const DarkHelpLine = '/static/icon-disclaimer-dark.svg';
const WhiteHelpLine = '/static/questionmark_circle_white.svg';

const HelpLineInfo = ({style, dark = false, emptyText = false}) => {
    const [open, toggleModal] = useState(false);

    return (
        <>
            <span
                className="help-line-info icon"
                style={style}
                onClick={() => toggleModal(true)}>
                {emptyText ? (
                    <IconQuestionCircle
                        fill="var(--primary-300)"
                        width="16px"
                        height="16px"
                    />
                ) : (
                    <img
                        src={dark ? DarkHelpLine : WhiteHelpLine}
                        alt="American Addictions centers help information"
                    />
                )}
            </span>

            {open && (
                <Modal onClickOutside={() => toggleModal(false)}>
                    <div className="modal" style={{fontStyle: 'normal'}}>
                        <div className="modal-close" onClick={() => toggleModal(false)}>
                            ✕
                        </div>

                        <div className="modal-content">
                            <div className="modal-title">About the AAC Helpline</div>
                            <p>
                                American Addiction Centers (AAC) is the leading provider
                                for addiction treatment across the United States,
                                specializing in evidence-based treatment and mental
                                healthcare. With 9 locations, AAC has a facility near you
                                that is ready to help you start your journey to sobriety
                                today.
                            </p>
                            <p>
                                When you call the helpline displayed on{' '}
                                <Link href="/">withdrawal.net</Link>, you will be
                                connected with a caring admissions navigator to discuss
                                your options for treatment. Our representatives work
                                solely for AAC and will discuss whether an AAC facility
                                may be an option for you.
                            </p>
                            <p>
                                Our helpline is offered at no cost to you and with no
                                obligation to enter into treatment. Neither{' '}
                                <Link href="/">withdrawal.net</Link> nor AAC receives any
                                commission or other fee that is dependent upon which
                                treatment provider a visitor may ultimately choose.
                            </p>
                            <p>
                                For more information, visit{' '}
                                <a
                                    href="https://americanaddictioncenters.org/"
                                    target="_blank"
                                    rel="noopener">
                                    americanaddictioncenters.org
                                </a>
                                .
                            </p>
                            <div style={{textAlign: 'center'}}>
                                <Image
                                    src="/static/aac-logo.png"
                                    alt="AAC logo"
                                    style={{
                                        maxWidth: 280,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}

            <style jsx>{`
                .help-line-info {
                    cursor: pointer;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                }
                .help-line-info span {
                    height: 20px;
                    width: 120px;
                }
                .modal p {
                    font-size: 14px;
                    line-height: 22px;
                    color: #1a1a1a;
                }
                .modal :global(a) {
                    color: var(--primary-300);
                    text-decoration: none;
                }
                .modal :global(a:hover),
                .modal :global(a:focus) {
                    color: #3a3a3a;
                    text-decoration: underline;
                }
                .modal {
                    max-width: 500px;
                    height: auto;
                    background-color: #ffffff;
                    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
                    margin: 18px;
                    position: relative;
                    width: 100%;
                    padding: 55px 25px;
                    text-align: left;
                }
                .modal-close {
                    position: absolute;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                    font-size: 20px;
                    color: #fff;
                    background-color: var(--primary-300);
                    height: 35px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .modal-close:hover {
                    cursor: pointer;
                }
                .modal-title {
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 25px;
                    color: #000;
                    text-align: center;
                }
                .modal-content {
                    min-height: 400px;
                    max-height: 500px;
                    overflow: scroll;
                }
            `}</style>
        </>
    );
};

export default HelpLineInfo;
