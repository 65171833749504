import {useContext} from 'react';
import CallTrackingNumber from 'aac-components/components/CallTrackingNumber';
import styled from 'styled-components';
import {AppContext} from '../../pages/_app';

const ButtonStyles = styled.div`
    .cl-call-tracking {
        color: var(--white);
        background-color: var(--interactive-300);
        display: inline-flex;
        line-height: 16px;
        padding: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        -webkit-box-pack: center;
        justify-content: center;
        appearance: none;
        border: none;
        border-radius: 8px;
        width: 100%;
    }
    .cl-call-tracking:hover {
        text-decoration: none;
        background-color: var(--primary-400);
        outline: 0;
    }
`;

export const swappedCallTrackingNumber = () => {
    const {callTrackingNumber} = useContext(AppContext);
    return callTrackingNumber;
};

const CallTrackingNumberWrapper = (props) => {
    const {prefix = '', isButton = false, displayNumber = true} = props;
    const {callTrackingNumber} = useContext(AppContext);

    let displayText = callTrackingNumber?.display;

    displayText =
        prefix && displayNumber
            ? `${prefix} ${displayText}`
            : displayNumber
            ? displayText
            : prefix;

    return (
        <>
            {isButton ? (
                <ButtonStyles>
                    <CallTrackingNumber
                        callTrackingNumber={{
                            href: callTrackingNumber?.href,
                            display: displayText,
                        }}
                        {...props}
                    />
                </ButtonStyles>
            ) : (
                <CallTrackingNumber
                    callTrackingNumber={{
                        href: callTrackingNumber?.href,
                        display: displayText,
                    }}
                    {...props}
                />
            )}
        </>
    );
};

export default CallTrackingNumberWrapper;
