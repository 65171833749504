import css from 'styled-jsx/css';
import {zIndex} from 'aac-components/utils/helpers';

// COLORS
export const primary = (shade) => {
    const shades = {
        100: '#FEF0F2',
        200: '#FAC4CE',
        300: '#EF395A',
        400: '#C7334E',
        default: '#EF395A',
    };
    return shades[+shade] || shades.default;
};

export const secondary = (shade) => {
    const shades = {
        100: '#E8F1F5',
        200: '#B6D4DF',
        300: '#0A6F93',
        400: '#055276',
        default: '#0A6F93',
    };
    return shades[+shade] || shades.default;
};

export const tertiary = '#1B222C';

export const blue = (shade) => {
    const shades = {
        100: '#eef5fc',
        200: '#4E9EE0',
        300: '#182957',
        400: '#1C2838',
        500: '#354763',
        550: '#0F2555',
        600: '#2F77B2',
        700: '#27323F',
        default: '#2F77B2',
    };
    return shades[+shade] || shades.default;
};

export const orange = (shade) => {
    const shades = {
        400: '#F6952D',
        500: '#D28430',
        600: '#C67125',
        default: '#D28430',
    };

    return shades[+shade] || shades.default;
};

export const gray = (shade) => {
    const shades = {
        100: '#F8F8F8',
        150: '#f9f9f9',
        200: '#eeeeee',
        300: '#cccccc',
        400: '#6A6A6A',
        500: '#1a1a1a',
        600: '#1b222c',
        default: '#1a1a1a',
    };

    return shades[shade] || shades.default;
};

export const red = (shade) => {
    const shades = {
        200: '#DD4132',
        default: '#B03241',
    };

    return shades[+shade] || shades.default;
};

export const system = (shade) => {
    const shades = {
        success: '#49a147',
        warning: '#ffad0d',
        error: '#e35141',
        focus: '#005FCC',
        default: '#49a147',
    };

    return shades[shade] || shades.default;
};

export const dropShadow = (shadow) => {
    const shadows = {
        400: '0 5px 20px -5px rgba(0,0,0,0.15)',
        default: '0px 0px 41px 15px rgba(0, 0, 0, 0.03)',
    };
    return shadows[+shadow] || shadows.default;
};

export const overlay = (overlay) => {
    const overlays = {
        gradient:
            'linear-gradient(to right, rgba(255,255,255,.9) , rgba(255,255,255,.4));',
        saturation: 'rgba(255,255,255,.5)',
        default: 'rgba(255,255,255,.5)',
    };
    return overlays[overlay] || overlays.default;
};

export const px = (value) => {
    if (typeof value === 'number') {
        return value + 'px';
    } else if (typeof value === 'string') {
        /**
         * Check if already ends with px, em, rem, etc.
         */
        const hasLetters = value.match(/\d/g);

        if (hasLetters) {
            let numsOnly = hasLetters.join('');
            return numsOnly + 'px';
        }

        return value + 'px';
    } else {
        console.log('Must pass string or number to px()');
        return '25px';
    }
};

export const BREAKPOINT_VALUE = 992;
export const BREAKPOINT = BREAKPOINT_VALUE + 'px';
export const BREAKPOINT_PX = px(BREAKPOINT);

export const MAX_WIDTH = '1200';
export const MAX_WIDTH_PX = px(MAX_WIDTH);

// GLOBAL STYLES
export const globalStyles = `
	/* latin */
	@font-face {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: local('Inter Regular'), local('Inter-Regular'),
		url(/static/fonts/inter-regular.woff2) format('woff2'),
		url(/static/fonts/inter-regular.woff) format('woff');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
	/* latin */
	@font-face {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-display: swap;
		src: local('Inter SemiBold'), local('Inter-SemiBold'),
		url(/static/fonts/inter-semibold.woff2) format('woff2'),
		url(/static/fonts/inter-semibold.woff) format('woff');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
	/* latin */
	@font-face {
		font-family: "Inter";
		font-style: normal;
		font-weight: 800;
		font-display: swap;
		src: local('Inter Bold'), local('Inter-Bold'),
		url(/static/fonts/inter-bold.woff2) format('woff2'),
		url(/static/fonts/inter-bold.woff) format('woff');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}
	:root {
		--success: #49a147;
		--warning: #ffad0d;
		--error: #e35141;
		--focus: #005FCC;

		--title-font: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
		--copy-font: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
		--font-base: 16px;
		--font-base-height: 25px;

		--box-shadow: 0px 0px 41px 15px rgba(0, 0, 0, 0.03);

		--primary: #EF395A;
		--primary-100: #FEF0F2;
		--primary-200: #FAC4CE;
		--primary-300: #EF395A;
		--primary-400: #C7334E;

		--secondary: #0A6F93;
		--secondary-100: #E8F1F5;
		--secondary-200: #B6D4DF;
		--secondary-300: #0A6F93;
		--secondary-400: #055276;

		--tertiary: #1B222C;
		--tertiary-400: #043859;

		--interactive: var(--primary);
		--interactive-100: var(--primary-100);
		--interactive-200: var(--primary-200);
		--interactive-300: var(--primary-300);
		--interactive-400: var(--primary-400);

		--gray: #1a1a1a;
		--gray-100: #F8F8F8;
		--gray-150: #f9f9f9;
		--gray-200: #eeeeee;
		--gray-300: #cccccc;
		--gray-400: #6A6A6A;
		--gray-500: #1a1a1a;
		--gray-600: #1b222c;

		--white: #ffffff;
		--black: #000000;

		--h1-size: 40px;
		--h1-size-mobile: 35px;
		--h2-size: 32px;
		--h3-size: 24px;
		--h4-size: 20px;

		--p-size-lg: 24px;
		--p-size-md: 20px;
		--p-size-sm: 16px; /* root */
		--p-size-xs: 12px;

		--label-size: 12px;
	}

	html, body {
		font-family: var(--copy-font);
		color: var(--gray-500);
		font-size: var(--font-base);
	}
	html {
		height: 100%;
		width: 100%;
		-webkit-font-smoothing: antialiased;
		overflow-x: hidden;
	}

	h1,
	h2,
	h3,
	h4 {
		font-family: var(--title-font);
		font-weight: bold;
		line-height: 1.2;
		letter-spacing: -0.48px;
		margin-bottom: 15px;
	}
	h1 {
		font-size: var(--h1-size);
		line-height: 48px;
		font-weight: 700;
	}

	.h1,
	.text-4xl {
		font-size: 36px;
		line-height: 44px;
		font-weight: bold;
	}
	.h2,
	.text-3xl {
		font-size: 30px;
		line-height: 40px;
		font-weight: bold;
	}
	.h3,
	.text-2xl {
		font-size: 24px;
		line-height: 32px;
		font-weight: bold;
	}
	.h4,
	.text-xl {
		font-size: 20px;
		line-height: 32px;
		font-weight: bold;
	}

	.title-lg,
	.title-md,
	.title-sm {
		font-family: var(--title-font);
		font-weight: 700;
		margin: 12px 0 24px 0;
		letter-spacing: -0.04em;
	}
	.title-lg  {
		font-size: var(--h2-size);
		line-height: 36px;
	}
	.title-md  {
		font-size: var(--h3-size);
		line-height: 27px;
	}
	.title-sm {
		font-size: var(--h4-size);
		line-height: 18px;
	}
	@media screen and (min-width: ${BREAKPOINT}){
		h1 {
			font-size: var(--h1-size);
			line-height: 48px;
		}
	}
	h2 {
		font-size: var(--h2-size);
		line-height: 40px;
	}
	h3 {
		font-size: var(--h3-size);
		line-height: 32px;
	}
	h4 {
		font-size: var(--h4-size);
		line-height: 24px;
	}
	h1-hero {
		font-size: 48px;
		line-height: 56px;
	}

	p {
		font-size: var(--p-size-sm);
		font-weight: 400;
		line-height: 24px;
		lett-spacing: -0.16px;

		margin: 0 0 24px 0;
		word-break: break-word;
	}
	@media screen and (min-width: ${BREAKPOINT}){
		p {
			font-size: 16px;
			margin: 0 0 26px 0;
		}
	}
	p-lg {
		font-size: var(--p-size-lg);
		line-height: 32px;
	}
	p-md {
		font-size: var(--p-size-md);
		line-height: 32px;
	}
	p-xs {
		font-size: var(--p-size-xs);
		line-height: 16px;
	}

	label {
		font-size: var(--label-size);
		display: inline-block;
		padding-bottom: 5px;
	}

	div {
		font-size: var(--font-base);
		line-height: var(--font-base-height);
	}

	.text-md {
		font-family: var(--title-font);
		font-weight: bold;
		font-size: 20px;
		line-height: 30px;
		letter-spacing: -0.48px;
	}
	.text-sm{
		font-size: 16px;
		line-height: 24px;
	}
	.text-xs {
		font-family: var(--title-font);
		font-weight: normal;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: -0.16px;
	}

	.text-xs a {
		color: var(--primary);
		font-family: var(--title-font);
		font-weight: normal;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: -0.16px;
	}

	a:hover {
		text-decoration: underline;
	}

	button {
		padding: 15px 5px;
		border-radius: 8px;

		font-size: 12px;
		font-weight: 600;
		letter-spacing: -0.16px;
		line-height: 16px;
		text-align: center;
	}

	button a {
		text-decoration: none;
		font-family: var(--copy-font);
		margin: 15px 0;
		color: inherit;
	}
	button a:hover {text-decoration: none;}

	/* Tags */
	content-tag {
		background: var(--primary-100);
		border-radius: 4px;
		height: 24px;
		width: 85px;

		color: var(--primary);
		font-family: var(--copy-font);
		font-size: 12px;
		font-weight: normal;
		letter-spacing: -0.16px;
		line-height: 16px;
	}

	blockquote {
		font-size: 23px;
		letter-spacing: -0.3px;
		line-height: 34px;
	}
	input, textarea {
		padding: 5px 10px;
		border: 1px solid var(--gray-400);
		display: block;
	}
	iframe {
		max-width: 100%;
	}
	img {
		vertical-align: middle;
		max-width: 100%;
		height: auto;
		width: auto;
	}
	ul, ol {
		padding: 0 0 0 20px;
		margin: 10px 0;
		line-height: 1.6;
	}

	ol li {
		padding-left: 10px;
	}

	hr {
		margin: 50px 0;
		border: 1px solid #f0f0f0;
	}

	.error-message {
		color: var(--error);
		padding-top: 10px;
		font-size: 13px;
		font-weight: bold;
	}

	/* Make clicks pass-through */
	#nprogress {
		pointer-events: none;
	}

	#nprogress .bar {
		background: var(--primary);

		position: fixed;
		z-index: ${zIndex('wpAdminBar')};
		top: 0;
		left: 0;

		width: 100%;
		height: 2px;
	}

	/* Fancy blur effect */
	#nprogress .peg {
		display: block;
		position: absolute;
		right: 0px;
		width: 100px;
		height: 100%;
		box-shadow: 0 0 10px #fff, 0 0 5px #fff;
		opacity: 1;

		-webkit-transform: rotate(3deg) translate(0px, -4px);
		-ms-transform: rotate(3deg) translate(0px, -4px);
		transform: rotate(3deg) translate(0px, -4px);
	}

	/* Remove these to get rid of the spinner */
	#nprogress .spinner {
		display: block;
		position: fixed;
		z-index: ${zIndex('wpAdminBar')};
		top: 15px;
		right: 18px;
	}

	#nprogress .spinner-icon {
		width: 18px;
		height: 18px;
		box-sizing: border-box;

		border: solid 2px transparent;
		border-top-color: #fff;
		border-left-color: #fff;
		border-radius: 50%;

		-webkit-animation: nprogress-spinner 400ms linear infinite;
		animation: nprogress-spinner 400ms linear infinite;
	}

	.nprogress-custom-parent {
		overflow: hidden;
		position: relative;
	}

	.nprogress-custom-parent #nprogress .spinner,
	.nprogress-custom-parent #nprogress .bar {
		position: absolute;
	}

	@-webkit-keyframes nprogress-spinner {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes nprogress-spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	#onrWebchat {
		bottom: 0px !important;
		top: 0px !important;
		max-width: 100vw !important;
		max-height: 100vh !important;
	}
	@media screen and (min-width: ${BREAKPOINT}) {
		#onrWebchat {
			bottom: 86px !important;
			max-width: 442px !important;
			max-height: calc(-86px + 90dvh) !important;
			top: 120px !important;
		}
	}

	#onrWebchat .conversation-launcher {
		display: none;
	}
	#onrWebchat.onrWebchat-show {
		display: block;
	}
	#onrWebchat .ant-card-body .message-container + div .ant-btn-link {
		display: flex;
		position: absolute;
	}

	/* Drift container */
	#drift-frame-chat, #drift-frame-controller {
		z-index: ${zIndex('floatingActionButton')} !important;
	}
	#drift-frame-controller {
		bottom: 200px !important;
	}
	#drift-frame-chat .drift-frame-chat {
		margin-top: 0;
	}
	@media (min-width: ${BREAKPOINT}) {
		#drift-frame-controller {
			bottom: 165px !important;
			max-height: 70vh !important;
		}
		#drift-frame-chat {
			bottom: 205px !important;
		}
	}
	@media screen and (min-width: ${BREAKPOINT}) {
		#nprogress .spinner {
			top: 15px;
			right: 15px;
		}
		#nprogress .peg {
			box-shadow: 0 0 10px var(--primary), 0 0 5px var(--primary);
		}
		#nprogress .spinner-icon {
			border: solid 2px transparent;
			border-top-color: var(--primary);
			border-left-color: var(--primary);
		}
	}

	/* Text Highlight/Shine animation  */
	.effect-highlight-shine-animation {
		display: inline-block;
		position: relative;
		-webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
		-webkit-mask-size: 200%;
		-webkit-animation: highlighter 2s linear infinite;
	}
	@keyframes highlighter {
		from { -webkit-mask-position: 150%; }
		to { -webkit-mask-position: -50%; }
	}
`;

export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
              result[3],
              16,
          )}`
        : '0, 0, 0';
}

export const btnStyles = css.resolve`
    button {
        font-size: 13px;
        border-radius: 5px;
        max-height: 50px;
        margin: 30px 0;
        text-align: center;
        width: 100%;
        min-width: 220px;
        max-width: 380px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 600;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        button {
            max-width: 280px;
        }
    }

    button:hover {
        cursor: pointer;
        transition: all 0.25s;
    }
`;
