import React from 'react';
import {zIndex} from 'aac-components/utils/helpers';

class Modal extends React.Component {
    handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            this.props.onClickOutside(e);
        }
    };

    componentDidMount() {
        if (typeof document === 'undefined') return;
        document.addEventListener('keydown', this.handleKeyDown, false);
    }

    componentWillUnmount() {
        if (typeof document === 'undefined') return;
        document.removeEventListener('keydown', this.handleKeyDown, false);
    }

    handleClick = (e) => {
        // console.log(e.target.classList.contains('overlay'));
        if (e && e.target && e.target.classList.contains('overlay')) {
            this.props.onClickOutside();
        }
    };

    render() {
        return (
            <>
                <div className="overlay" onClick={this.handleClick}>
                    {this.props.children}
                </div>
                <style jsx>{`
                    div {
                        background-color: rgba(0, 0, 0, 0.5);
                        position: fixed;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: ${zIndex('modal')};
                    }

                    .overlay {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                `}</style>
            </>
        );
    }
}

export default Modal;
